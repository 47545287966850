import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  menuIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  loginOutButton: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
  },
  helpText: {
    overflowWrap: 'break-word',
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.fontSize,
  },
});

export const useButtonClasses = makeStyles({
  root: {
    display: 'block',
    textAlign: 'left',
    marginBottom: theme.spacing(1.5),
    width: '100%',
  },
});
