import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import { getUserEmail, removeUser } from 'apputil/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faSignIn } from '@fortawesome/pro-regular-svg-icons';
import { Button, FormHelperText } from '@material-ui/core';

import { LOGOUT_REDIRECT_URL } from 'apputil/auth-0';
import { LOGOUT } from 'constants/navigation';

import useStyles, { useButtonClasses } from './styles';

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const getIcon = (loggedIn) => (loggedIn ? faSignOut : faSignIn);

const LogInOutNav = ({
  setWaiting,
  onAction = () => {},
  loggedIn,
}) => {
  const styles = useStyles();
  const buttonClasses = useButtonClasses();

  const [working, setWorking] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    setIsLoggedIn(loggedIn);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Button
       classes={buttonClasses}
       variant="outlined"
       className={styles.loginOutButton}
       aria-label={`${isLoggedIn ? 'LOGOUT' : 'LOGIN'}`}
       disabled={working}
       color="secondary"
       onClick={() => {
         setWorking(true);
         setWaiting(true);
         setTimeout(() => {
           if (isLoggedIn) {
             removeUser();
             logout({ returnTo: LOGOUT_REDIRECT_URL, appState: {} });
             onAction();
             navigate(LOGOUT);
           } else {
             const email = getUserEmail() || '';
             if (emailRegex.test(email)) {
               loginWithRedirect({
                 prompt: 'select_account',
                 login_hint: email,
                 appState: {},
               }).then(() => {
                 onAction();
                 setWaiting(false);
               });
             } else {
               loginWithRedirect({
                 prompt: 'select_account',
                 appState: {},
               }).then(() => {
                 onAction();
                 setWaiting(false);
               });
             }
           }
         }, 100);
       }}
        >
        <FontAwesomeIcon
          icon={getIcon(isLoggedIn)}
          className={styles.menuIcon} size="lg"/>
     {`${isLoggedIn ? 'LOGOUT' : 'LOG IN'}`}
     </Button>
    <FormHelperText className={styles.helpText}>
      {`${isLoggedIn ? 'Exit the application' : 'Login to the application'}`}
    </FormHelperText>
  </>
  );
};

LogInOutNav.propTypes = {
  setWaiting: func.isRequired,
  onAction: func,
  loggedIn: bool.isRequired,
};

export default LogInOutNav;
